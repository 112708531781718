import React from 'react'
import { Helmet } from 'react-helmet'
import { Popover } from 'antd';
import CarouselIndex from '../../components/Carousel'

import '../../css/DownloadCenter.scss'


const Carousel_text = [
  {
    carouselImage: 'downloadCenter/img_download_enter_banner',
    text: [
      { text: ' · 下载中心', colorText: '趣来达' },
    ],
    scenes: [
      { text: 'DOWNLOAD CENTER' }
    ],
    background: '#403D4B'
  }
]

let pageTimer = '' //停留时间


class DispatchSystem extends React.Component {
  state = {

  };

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    if (window.location.hash) {
      return;
    }
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  componentWillMount() {
    let id = '#' + this.getQueryVariable('id')
    if (id !== "#undefined") {
      window.location.href = id
    }
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'download'
    }, 0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer, 'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'download',
        time: tempTimer
      }, 0)
    }
  }

  shouldComponentUpdate(newValue, Oldvalue) {
    let id = '#' + this.getQueryVariable('id')
    if (id !== "#undefined") {
      window.location.href = id
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>趣来达-用户端下载|商家端下载|骑手端下载|智能调度后台下载</title>
          <meta name="Description" content="趣来达用户端、商家端、骑手端、调度后台下载中心" />
          <meta name="Keywords" content="用户端下载,商家端下载,骑手端下载,智能调度后台下载" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text} />
        {/* 为什么选择趣来达外卖系统 */}
        <div className="d-flex justify-content-center" id="1">
          <div className="download-center">
            <div className="download-center d-flex " style={{ height: '600px', width: '1440px' }}>
              <img alt='' src={require(`../../asset/downloadCenter/img-download-center1.png`)} style={{ width: '720px', height: '500px', marginRight: "268px" }} />
              <div className="d-flex flex_column">
                <div style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '39px', color: '#121213' }}>
                  趣来达用户端
                </div>
                <div style={{ fontSize: '16px', marginRight: '39px', color: '#707070', lineHeight: '25px', marginBottom: "15px" }}>
                  借力微信生态巨大流量，打造方便用户使用的小程序与公众号H5网页<br/>端，即用即走，便捷下单
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "20px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center1.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    无需下载，随用随走
                  </div>
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "40px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center2.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    订单通知，及时触达
                  </div>
                </div>
                <div className="d-flex align-cen">
                  <div className="flex-c-c download-yellow-btn">
                    小程序体验
                    <img alt='' src={require(`../../asset/downloadCenter/wechat-user-code.jpg`)} />
                  </div>
                  <div className="flex-c-c download-hover-btn">
                    H5体验
                    <img alt='' src={require(`../../asset/downloadCenter/h5-user-code.png`)} />
                  </div>
                </div>
                <div className="d-flex align-cen" style={{marginTop: '20px'}}>
                  <div className="flex-c-c download-hover-btn">
                    支付宝小程序
                    <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/alipay.png' />
                  </div>
                  <Popover content={
                    <div className='d-flex align-items-center justify-content-center' style={{flexDirection: 'column',maxWidth: '300px',padding: '0 10px'}}>
                      <div className='fontWeight_bold' style={{marginBottom: '10px'}}>趣来达（用户APP）</div>
                      <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/user.png' style={{width: '150px',height: '150px',marginBottom: '10px'}} />
                      <div style={{wordBreak: 'break-all',marginBottom: '10px'}}>
                        ios下载地址:
                        <a href='https://apps.apple.com/cn/app/%E8%B6%A3%E6%9D%A5%E9%AA%91%E6%89%8B%E7%AB%AF/id1482891979'>
                          https://apps.apple.com/cn/app/%E8%B6%A3%E6%9D%A5%E9%AA%91%E6%89%8B%E7%AB%AF/id1482891979
                        </a>
                      </div>
                      <div style={{wordBreak: 'break-all'}}>
                        android下载地址:
                        <a href='http://www.qolai.com/userAppDownload?app_id=__UNI__08F3148'>
                          http://www.qolai.com/userAppDownload?app_id=__UNI__08F3148
                        </a>
                      </div>
                    </div>
                  } placement="bottom">
                    <div className="flex-c-c download-hover-btn">
                      用户APP
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center" id="2" style={{ background: '#F7F9FC' }}>
          <div className="download-center">
            <div className="download-center d-flex " style={{ height: '600px', width: '1440px' }}>
              <div className="d-flex flex_column">
                <div style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '39px', color: '#121213' }}>
                  趣来达骑手端
                </div>
                <div style={{ fontSize: '16px', marginRight: '39px', color: '#707070', lineHeight: '25px', marginBottom: "15px" }}>
                  来单及时响铃推送，通过“抢单+派单”模式确保平台所有订单及时送达
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "20px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center3.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    来单响铃，及时推送
                  </div>
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "40px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center4.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    支持转单，确保配送
                  </div>
                </div>
                <div className="d-flex align-cen">
                  <div className="flex-c-c download-yellow-btn">
                    <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(2).png' />
                    安卓下载体验
                  </div>
                  <div className="flex-c-c download-hover-btn">
                    <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(1).png' />
                    IOS下载体验
                  </div>
                </div>
              </div>
              <img alt='' src={require(`../../asset/downloadCenter/img-download-center2.png`)} style={{ width: '720px', height: '500px', marginLeft: "270px" }} />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center" id="3">
          <div className="download-center">
            <div className="download-center d-flex " style={{ height: '600px', width: '1440px' }}>
              <img alt='' src={require(`../../asset/downloadCenter/img-download-center3.png`)} style={{ width: '720px', height: '500px', marginRight: "268px" }} />
              <div className="d-flex flex_column">
                <div style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '39px', color: '#121213' }}>
                  趣来达商家端
                </div>
                <div style={{ fontSize: '16px', marginRight: '39px', color: '#707070', lineHeight: '25px', marginBottom: "15px" }}>
                  独立APP操作，快捷下单，及时接单，设置各类营销活动，活跃店铺用户
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "20px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center5.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    拍照下单，快速发单
                  </div>
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "40px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center6.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    自动接单，提高效率
                  </div>
                </div>
                <div className="d-flex align-cen">
                  <div className="flex-c-c download-yellow-btn" style={{ width: '130px' }}>
                    <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(4).png' />
                    安卓下载体验
                  </div>
                  <div className="flex-c-c download-hover-btn" style={{ width: '130px' }}>
                    <img alt='' src='https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code(3).png' />
                    IOS下载体验
                  </div>
                </div>
                <div className="d-flex align-cen" style={{marginTop: '20px'}}>
                  <div className="flex-c-c download-hover-btn" style={{ width: '130px' }}
                    onClick={() => window.location.href = 'https://www.qolai.com/store'}>
                    商户后台体验
                  </div>
                  <Popover content={<a href='http://www.qolai.com/pcDownload?path=qolai_merchant_pc'>http://www.qolai.com/pcDownload?path=qolai_merchant_pc</a>} placement="bottom">
                    <div className="flex-c-c download-hover-btn" style={{ width: '140px' }}>
                      商户PC客户端
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center" id="4" style={{ background: '#F7F9FC', marginBottom: '-100px' }}>
          <div className="download-center">
            <div className="download-center d-flex " style={{ height: '600px', width: '1440px' }}>

              <div className="d-flex flex_column">
                <div style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '39px', color: '#121213' }}>
                  调度后台
                </div>
                <div style={{ fontSize: '16px', marginRight: '39px', color: '#707070', lineHeight: '25px', marginBottom: "15px" }}>
                  借力微信生态巨大流量，打造方便用户使用的小程序与公众号H5网页端，即用即走，便捷下单
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "20px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center7.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    智能管理，全局掌控
                  </div>
                </div>
                <div className="d-flex align-cen" style={{ marginBottom: "40px" }}>
                  <img alt='' src={require(`../../asset/downloadCenter/icon-download-center8.png`)} style={{ width: '30px', height: '30px', marginRight: "16px" }} />
                  <div style={{ fontSize: '20px', color: '#121213' }}>
                    制定平台规则，合理运营
                  </div>
                </div>
                <div className="d-flex align-cen">
                  <div className="flex-c-c download-yellow-btn"
                    onClick={() => 
                      this.props.onVisitClick({
                        type: 3,
                        domain: 'www.qolai.com',
                        page: 'download',
                        button: 'registrationExperience'
                      }, () => window.location.href = 'https://www.qolai.com/admin')
                    }>
                    后台注册体验
                  </div>
                  <Popover content={<a href='http://www.qolai.com/pcDownload?path=qolai_admin_pc'>http://www.qolai.com/pcDownload?path=qolai_admin_pc</a>} placement="bottom">
                    <div className="flex-c-c download-hover-btn" style={{width: '172px'}}>
                      调度后台PC客户端
                    </div>
                  </Popover>
                </div>
              </div>
              <img alt='' src={require(`../../asset/downloadCenter/img-download-center4.png`)} style={{ width: '720px', height: '500px', marginRight: "268px" }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DispatchSystem;