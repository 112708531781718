import React from 'react';
import {
  navBarArr
} from '../mock/index';
import {
  getURLParameters
} from '../utils/index';

import {
  Link
} from 'react-router-dom';

import logo from '../asset/logo.png'
import logo1 from '../asset/logo1.png'

class Header extends React.PureComponent {
  state = {
    isOpen: false,
    isActive: 0,
    isShowSecondLevel: false,
    secondLevel: [],
    levelActive: null,
    scrollTop: 0
  };
  componentDidMount() {
    const urlParams = getURLParameters(window.location.href);
    console.log((sessionStorage.getItem("isActive")))
    /* window.location.pathname */
    let index = 0;
    let index1 = -1;
    index = navBarArr.findIndex(item => item.path === window.location.pathname);
    index1 = navBarArr[navBarArr.findIndex(item => item.list)].list.findIndex(item => item.path == window.location.pathname);
    sessionStorage.setItem("isActive", index);
    index1 !== -1 && sessionStorage.setItem("secondLeveId", index1);
    this.setState({
      isActive: Number(sessionStorage.getItem("isActive") || 0),
      levelActive: sessionStorage.getItem("secondLeveId")
    })

    window.addEventListener('scroll', this.bindHandleScroll)
  };
  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindHandleScroll);
  }
  bindHandleScroll = (event) => {
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false)
      || window.pageYOffset
      || (event.srcElement ? event.srcElement.body.scrollTop : 0);
    this.setState({
      scrollTop
    })
  }

  render() {
    const { scrollTop } = this.state;
    // 一级导航
    let navBarRender = []
    // 二级导航
    let secondLevelTemp = []

    navBarArr.forEach((item, index) => {
      if (item.path) {
        navBarRender.push((
          <li
            className={`menu-li ${this.state.isActive === index && 'menu-li-active'} ${scrollTop > 100 && 'menu-li-black'} `}
            key={index}
            onClick={() => {
              this.setState({ isActive: index, levelActive: -1, isShowSecondLevel: false })
              sessionStorage.setItem("isActive", index);
              sessionStorage.setItem("secondLeveId", -1);
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              this.props.onShow(true);
            }
            }
          >
            <Link to={`${item.path}`} className='d-flex align-items-center justify-content-center'>
              <span className="menu-li-title">{item.title}</span>
            </Link>
          </li>
        ))
      }
      else {
        secondLevelTemp = item.list
        navBarRender.push((
          <li
            className={`menu-li menu-list ${scrollTop > 100 && 'menu-li-black'} ${this.state.isActive === 1 && 'menu-li-active'}`}
            key={index}
            onMouseEnter={() => {
              this.setState({ isShowSecondLevel: true })
            }
            }
          >
            <div className='d-flex align-items-center justify-content-center'>
              <span className="menu-li-title">{item.title}</span>
            </div>
          </li>
        ))
      }
    })

    return (
      <div style={{height: '80px'}}>
        <input type="checkbox" checked={this.state.isOpen} id="menustate" style={{ display: 'none' }} />
        <header id="global-nav-openmenu" className={this.state.isOpen ? 'openMenu global-nav w-100' : 'global-nav w-100'}
          style={{ backgroundColor: '#fff',boxShadow:'0px 4px 16px 0px rgba(104,104,110,0.1)'}}>
            {/* style={{ backgroundColor: `rgba(255, 255, 255,${scrollTop / 700} )` }} */}
          <div style={{ minWidth: '1300px' }} className={this.state.isOpen ? 'container open' : 'container'}>
            <div style={{ width: 180 }}>
              <img alt="title" src={logo} style={{ width: '140px', height: '42px'}} />
            </div>
            <div className="icon" onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}>
              <em></em>
              <em></em>
              <em></em>
            </div>
            <div className="menu-main">
              <ul className={`menu align-items-center mb-0 p-0 ${this.state.isOpen ? '' : 'hiddenNav'}`}>
                {navBarRender}
                <Link to="/manuell" onClick={() => this.setState({isActive: -1})} className="menu-li-btn text-center">
                  产品手册
                </Link>
                <li onClick={() => {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.com',
                    page: 'header',
                    button: 'onDemoAddress'
                  }, () => window.location.href = 'https://www.qolai.com/admin#/user/register?referrer=sc');
                }} className="menu-li-btn text-center" style={{marginLeft: '25px'}}>
                  演示地址
                </li>
              </ul>
            </div>
          </div>
          <div onMouseLeave={() => { this.setState({ isShowSecondLevel: false }) }} className={`w-100 bg-white second-level-box justify-content-center animated ${this.state.isShowSecondLevel ? 'd-flex fadeIn' : 'd-none'}`}>
            <div className="second-level-center d-flex">
              {secondLevelTemp.map((v, index) => {
                return (
                  <div key={index} className='second-level-list'>
                    <div className="second-level-list-top">
                      <img alt='' src={require(`../asset/Header/${v.icon}.png`)} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                      <span style={{fontSize: '20px'}}>{v.title}</span>
                    </div>
                    <span className="second-level-list-line" style={{ width: index + 1 === secondLevelTemp.length ? '0px' : '' }} />
                    {
                      v?.arr?.map((item, k) => {
                        return (
                          <Link
                            key={k}
                            className={`second-level d-flex ${this.state.levelActive === item.id ? 'second-level-active' : ''}`}
                            onClick={() => {
                              this.setState({ levelActive: item.id, isActive: 1, isShowSecondLevel: false })
                              sessionStorage.setItem("secondLeveId", item.id);
                              sessionStorage.setItem("isActive", 1);
                              document.body.scrollTop = document.documentElement.scrollTop = 0;
                              this.props.onShow(true);
                              console.log(this.state.isActive)
                            }}
                            to={`${item.path}`}
                          >
                            <div style={{ fontSize: 16, color: '#333333' }} className="second-level-title">
                              <span className="second-level-title-span">
                                {item.title}
                                {
                                  item.tipType ?
                                    (
                                      <span className="second-level-type" style={{ background: item.tipType === 'hot' ? '#FD634E' : item.tipType === 'new' ? '#2BCF88' : '#F6BB07' }}>
                                        {item.tipType === 'hot' ? '热' : item.tipType === 'new' ? '新' : '试'}
                                      </span>
                                    ) : ''

                                }
                              </span>
                            </div>
                          </Link>
                        )
                      })
                    }
                  </div>
                )
              })}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;