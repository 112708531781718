import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/city_service_system.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';

// 板块二数据
const plate2 = {
    tabs: [
        {
            title: '跑腿配送',
        },
        {
            title: '外卖服务',
        },
        {
            title: '信息平台',
        },
        {
            title: '团购到店',
        }
    ],
    plate2List: [
        {
            pach: '/errands',
            image: 'plate2List1',
            data: [
                {
                    title: '跑腿帮办',
                    cont: '代办所涉及的所有跑腿服务，代买、代送、代取、代办、代排队都可以设置场景发布。',
                },
                {
                    title: '家政服务',
                    cont: '支持设置家政相关的服务，包括房屋清洁、疏通管道、家电维修、上门做饭等等。',
                },
                {
                    title: '代驾打车',
                    cont: '支持开设代驾、打车等服务，类似滴滴平台，自定义场景配置和计价方案。',
                },
                {
                    title: '货运搬家',
                    cont: '类似货拉拉的货运搬家平台，自定义设置场景内容和信息填写，支持货到付款。',
                }
            ]
        },
        {
            pach: '/campus',
            image: 'plate2List2',
            data: [
                {
                    title: '餐饮外卖',
                    cont: '可邀请本地所有餐饮类商家入驻，提供丰富的餐饮美食外送服务。',
                },
                {
                    title: '蛋糕甜品',
                    cont: '支持蛋糕、甜点、饮品、下午茶等美食外送，精致白领生活。',
                },
                {
                    title: '生鲜果蔬',
                    cont: '本地蔬菜、水果、生鲜等食材商品，支持线上购买、配送到家。',
                },
                {
                    title: '超市店铺',
                    cont: '以商品分类，可将超市、药店等类型的店铺整合归类。',
                }
            ]
        },
        {
            pach: '/copy',
            image: 'plate2List3',
            data: [
                {
                    title: '表白墙',
                    cont: '打造校园热门信息交流平台，表白互动，提升平台热度与用户黏性。',
                },
                {
                    title: '兼职招聘',
                    cont: '支持发布兼职、招聘等岗位信息，有意向可以直接联系，打造互动平台。',
                },
                {
                    title: '二手交易',
                    cont: '信息分类可设置发布闲置商品的栏目，实现资源互换。',
                },
                {
                    title: '本地资讯',
                    cont: '可发布本地/校园的实时资讯，大家可以交流探讨，提升舆论热度。',
                }
            ]
        },
        {
            pach: '/group',
            image: 'plate2List4',
            data: [
                {
                    title: '特色美食',
                    cont: '本地特色餐馆美食，可以通过线上引流客户到线下消费，为门店导流。',
                },
                {
                    title: '酒店民宿',
                    cont: '支持线上选购房间，用户线下核销二维码消费，完成住宿。',
                },
                {
                    title: '景区门票',
                    cont: '支持线上预定景区门票或服务项，线下核销完成消费，为景区引流。',
                },
                {
                    title: '丽人美发',
                    cont: '包括美发在内的多项线下服务，都支持线上参与优惠团购，线下消费。',
                }
            ]
        }
    ]
}

const plate3List = [
    {
        title: '运营管理',
        data: ['高颜值页面模板', '丰富功能组件', '二级分类页面', 'DIY可视化配置', '自定义频道添加', '跳转外部小程序']
    },
    {
        title: '调度配送',
        data: ['智能调度', '手动指派', '抢单/转单', '回程单', '中转配送', '聚合配送']
    },
    {
        title: '骑手管理',
        data: ['实名认证', '平台保证金', '奖惩制度', '骑手排班', '等级制度', '骑手保险']
    },
    {
        title: '数据分析',
        data: ['用户数据分析', '商家经营数据', '平台经营数据', '商家数据分析', '骑手数据分析', '平台订单数据']
    }
]

const plate5List = [
    {
        title: '跑腿',
        image: 'plate5List0',
        data: ['页面风格自由切换配置', '服务场景内容自定义配置'],
        plate5Custom: [
            {
                background: '#D8DEF7',
                image: 'plate5TopImage1'
            },
            {
                background: '#FA7A2F',
                image: 'plate5TopImage2'
            },
            {
                background: '#F8CF51',
                image: 'plate5TopImage3'
            },
            {
                background: '#00B6F8',
                image: 'plate5TopImage4'
            }
        ]
    },
    {
        title: '外卖',
        image: 'plate5List1',
        data: ['页面模板类型自由选择', '页面模块自由拖动配置'],
        plate5Custom: [
            {
                background: '#F6BB07',
                image: 'plate5TopImage5'
            },
            {
                background: '#77CB5C',
                image: 'plate5TopImage6'
            },
            {
                background: '#FA7A2F',
                image: 'plate5TopImage7'
            },
            {
                background: '#D33A3C',
                image: 'plate5TopImage8'
            }
        ]
    },
    {
        title: '分类信息',
        image: 'plate5List2',
        data: ['信息分类名称自定义配置', '互动参数、举报自定义配置', '支持设置付费发布']
    },
    {
        title: '本地团购',
        image: 'plate5List3',
        data: ['自定义设置顶部轮播图', '支持商品搜索检索', '折扣/服务内容商家自己设置']
    }
]

const plate5CustomBottom = [
    {
        title: '首页',
        icon: [
            {
                id: 0,
                image: 'plate5CustomBottom1'
            },
            {
                id: 1,
                image: 'plate5CustomBottom2'
            },
            {
                id: 2,
                image: 'plate5CustomBottom3'
            }
        ]
    },
    {
        title: '订单',
        icon: [
            {
                id: 4,
                image: 'plate5CustomBottom4'
            },
            {
                id: 5,
                image: 'plate5CustomBottom5'
            },
            {
                id: 6,
                image: 'plate5CustomBottom6'
            }
        ]
    },
    {
        title: '我的',
        icon: [
            {
                id: 7,
                image: 'plate5CustomBottom7'
            },
            {
                id: 8,
                image: 'plate5CustomBottom8'
            },
            {
                id: 9,
                image: 'plate5CustomBottom9'
            }
        ]
    }
]
const plate5Icon = ['custom1', 'custom2', 'custom3']

const plate6List = [
    {
        data: [
            {
                title: '默认模板',
                icon: 'plate6icon1'
            },
            {
                title: '大屏地图',
                icon: 'plate6icon2'
            },
            {
                title: '简约模板',
                icon: 'plate6icon3'
            },
            {
                title: '组件模板',
                icon: 'plate6icon4'
            }
        ]
    },
    {
        data: [
            {
                title: '默认模板',
                icon: 'plate6icon5'
            },
            {
                title: '大屏地图',
                icon: 'plate6icon6'
            },
            {
                title: '简约模板',
                icon: 'plate6icon7'
            },
            {
                title: '组件模板',
                icon: 'plate6icon8'
            }
        ]
    },
    {
        data: [
            {
                title: '默认装修',
                icon: 'plate6icon9'
            },
            {
                title: '视频背景',
                icon: 'plate6icon10'
            },
            {
                title: '营销动图',
                icon: 'plate6icon11'
            },
            {
                title: '推荐商品',
                icon: 'plate6icon12'
            }
        ]
    }
]

let pageTimer = '' //停留时间

class CityServiceSystem extends React.Component {
    state = {
        phone: '',
        baneerIndex: 0,
        plate2Index: 0,
        plate5Index: 0,
        plate6Index: 0,
        plate5Image: 'plate5List0',
        plate5Show: false,
        plate5ShowTop: false,
        plate5CustomIndex: 0,
        plate5CustomBtmIndex: 1,
        plate5TopImage: 'plate5TopImage1'
    };

    componentWillMount() {
        // 获取进入页面的时间
            if (pageTimer === '') {
                pageTimer = parseInt(new Date().getTime() / 1000);
                console.log(pageTimer,'pageTimer')
            }
        this.props.onVisitClick({
          type: 1,
          domain: 'www.qolai.com',
          page: 'service'
        },0)
      }
    
      componentWillUnmount() {
        // 离开时间减去进入时间
        const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
        console.log(tempTimer,'tempTimer')
        // 清空时间戳
        pageTimer = '';
        if (tempTimer > 0) {
          this.props.onVisitClick({
            type: 2,
            domain: 'www.qolai.com',
            page: 'service',
            time: tempTimer
          },0)
        }
      }

    slick2Index = index => {
        this.slider.slick.slickGoTo(index)
        this.setState({
            plate2Index: Number(index)
        })
    }
    onPlate5Item = (index, image) => {
        this.setState({
            plate5Index: Number(index),
            plate5CustomIndex: 0,
            plate5CustomBtmIndex: 0,
            plate5Image: image,
            plate5Show: false,
            plate5TopImage: index === 0 ? 'plate5TopImage1': 'plate5TopImage5'
        })
    }

    slick6Index = index => {
        this.sliders.slick.slickGoTo(index)
        this.setState({
            plate6Index: Number(index)
        })
    }
    onCustom = (index, image) => {
        this.setState({
            plate5CustomIndex: Number(index),
            plate5ShowTop: true,
            plate5TopImage: image
        })
    }
    onCustomBtm = (index, image) => {
        this.setState({
            plate5CustomBtmIndex: Number(index),
            plate5Show: true
        })
        if (index === 1 || index === 2 || index === 3) {
            plate5Icon[0] = image
        }
        if (index === 4 || index === 5 || index === 6) {
            plate5Icon[1] = image
        }
        if (index === 7 || index === 8 || index === 9) {
            plate5Icon[2] = image
        }
    }
    render() {
        return (
            <div className="w-100" >
                <Helmet>
                    <title>趣来达-同城外卖配送|信息发布系统|外卖到店团购系统</title>
                    <meta name="Description" content="趣来达同城生活服务系统，涵盖外卖点餐、跑腿配送、信息发布、外卖团购、二手交易、兼职招聘等；充分挖掘本地/县城/乡镇/校园创业需求，融合多行业，趣来达提供优质的平台系统与保姆式创业扶持及技术、售后、运营指导。" />
                    <meta name="Keywords" content="跑腿配送,外卖点餐,信息发布,外卖团购,同城生活服务系统"/>
                </Helmet>
                <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
                    <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
                        <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
                            <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>挖掘<span style={{ color: '#F6BB07' }}>本地/县城/乡镇市场</span>创业需求，融合多行业</div>
                            <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>提供优质的平台系统与保姆式创业扶持及技术、售后、运营指导</span>
                            <div className='Carousel-button d-flex animated fadeInRight'>
                                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({ phone: e.target.value }) }} placeholder='请输入您的手机号' />
                                <div onClick={() => { 
                                    this.props.onVisitClick({
                                        type: 3,
                                        domain: 'www.qolai.com',
                                        page: 'service',
                                        button: 'onTrial'
                                    },0);
                                    this.props.submit(this.state.phone) 
                                }} className='Carousel-btn'>立即试用</div>
                            </div>
                        </div>
                    </div>
                </Carousel>
                {/**服务场景多样化  板块2**/}
                <div style={{ padding: '100px 0', background: '#F7F9FC' }}>
                    <Title title="服务场景多样化"
                        text="DIVERSIFIED SERVICE SCENARIOS"
                        width={710}
                        height={66} />
                    <div className={styles.plate2}>
                        <div className="d-flex align-items-center" style={{ borderBottom: '2px solid #E2E2E2', justifyContent: 'space-between', cursor: 'pointer' }}>
                            {plate2.tabs.map((row, index) => {
                                return (
                                    <div key={index} className={styles.plate2Tabs} onMouseMove={() => { this.slick2Index(index) }} >
                                        <span className={this.state.plate2Index === index ? `${styles.plate2TabsTitle} ${styles.plate2TabsTitleActive}` : `${styles.plate2TabsTitle}`}>{row.title}</span>
                                        <span className={this.state.plate2Index === index ? `${styles.plate2TabsLineActive} ${styles.plate2TabsLine}` : `${styles.plate2TabsLine}`}></span>
                                    </div>
                                )
                            })}
                        </div>
                        <Carousel dots={false} ref={slider => (this.slider = slider)} style={{ paddingTop: '50px' }}>
                            {
                                plate2.plate2List.map((item, index) => {
                                    return (
                                        <div key={index} className='d-flex align-items-center justify-content-center'>
                                            <div className='d-flex' style={{ flexDirection: 'column', flex: 1, paddingRight: '60px' }}>
                                                {
                                                    item.data.map((row, indexs) => {
                                                        return (
                                                            <div key={indexs} className={styles.plate2ContentData}>
                                                                <div style={{ color: '#121213', fontWeight: 'bold', marginBottom: '2px' }}>{row.title}</div>
                                                                <div>{row.cont}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div>
                                                <img src={require(`../../asset/city_service_system/${item.image}.png`)} style={{ width: '540px', height: '340px' }} alt=''></img>
                                                <div onClick={()=>{
                                                    this.props.onVisitClick({
                                                        type: 3,
                                                        domain: 'www.qolai.com',
                                                        page: 'service',
                                                        button: 'more'
                                                    },()=> window.location.href = item.pach);
                                                }} className='d-flex justify-content-end' style={{ marginTop: '30px' }}><div className={styles.plate2Btn}>查看更多 <span style={{ fontWeight: '700' }}>More+</span></div></div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </Carousel>
                    </div>
                </div>
                {/**功能内容  板块3**/}
                <div style={{ margin: '100px 0', background: '#FFFFFF' }}>
                    <Title title="功能内容"
                        text="FUNCTION CONTENT"
                        width={710}
                        height={66} />
                    <div className={styles.plate3}>
                        {plate3List.map((row, index) => {
                            return (
                                <div className={styles.plate3Item} key={index}>
                                    <div className={`d-flex align-items-center justify-content-center ${styles.plate3ItemTop} ${index === 0 ? styles.plate3ItemTop1 : index === 1 ? styles.plate3ItemTop2 : index === 2 ? styles.plate3ItemTop3 : styles.plate3ItemTop4}`}>{row.title}</div>
                                    <div className={styles.plate3Bottom}>
                                        {row.data.map((item, indexs) => {
                                            return (
                                                <div key={indexs}>{item}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/**营销功能  板块4**/}
                <div style={{ margin: '100px 0', background: '#FFFFFF' }}>
                    <Title title="营销功能"
                        text="MARKETING FUNCTION"
                        width={710}
                        height={66} />
                    <img src={require(`../../asset/city_service_system/plate4Image.png`)} style={{ width: '1280px', height: '734px', display: 'block', margin: '0 auto' }} alt=''></img>
                </div>
                {/**精美页面自定义  板块5**/}
                <div style={{ padding: '100px 0', background: '#F7F9FC' }}>
                    <Title title="精美页面自定义"
                        text="FINE PAGE CUSTOMIZATION"
                        width={710}
                        height={66} />
                    <div className={styles.plate5}>
                        <div className={styles.plate5Left}>
                            <span className={styles.plate5Title}>模块自定义设置</span>
                            <img src={require(`../../asset/city_service_system/plate5Image.png`)} className='d-block' style={{ width: '346px', height: '261px' }} alt=''></img>
                            <span className={styles.plate5Tip}>选择模块内容</span>
                            <div className={styles.plate5ItemBox}>
                                {
                                    plate5List.map((row, indexs) => {
                                        return (
                                            <span key={indexs} onMouseOver={() => { this.onPlate5Item(indexs, row.image) }} className={this.state.plate5Index === indexs ? `${styles.plate5Item} ${styles.plate5ItemActive}` : `${styles.plate5Item}`}>{row.title}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <img src={require(`../../asset/city_service_system/${this.state.plate5Image}.png`)} style={{ width: '389px', height: '731px' }} alt=''></img>
                            {
                                this.state.plate5ShowTop && <img src={require(`../../asset/city_service_system/${this.state.plate5TopImage}.png`)} style={{
                                    height: '82px',
                                    position: 'absolute',
                                    top: 37,
                                    left: 43,
                                    right: 45
                                }} alt=''></img>
                            }

                            {this.state.plate5Show && <div
                                className='d-flex justify-content-between align-items-center'
                                style={{
                                    background: '#fff',
                                    height: '22px',
                                    position: 'absolute',
                                    bottom: 75,
                                    left: 45,
                                    right: 45,
                                    padding: '0px 40px'
                                }} alt=''>
                                {
                                    plate5Icon.map((icon, iconIndex) => {
                                        return (
                                            <dic key={iconIndex} className='d-flex flex-column justify-content-center align-items-center'>
                                                <img src={require(`../../asset/city_service_system/${icon}.png`)} className='d-block' style={{ width: '18px', height: '18px' }} alt=''></img>
                                            </dic>
                                        )
                                    })
                                }
                            </div>
                            }
                        </div>
                        <div className={styles.plate5Right}>
                            {
                                plate5List[this.state.plate5Index].data.map((row, indexs) => {
                                    return (
                                        <>
                                            {this.state.plate5Index === 3 || this.state.plate5Index === 2 ?
                                                <span key={indexs} className={`${styles.plate5RightItem} ${indexs === 0 ? styles.plate5RightItem1 : indexs === 1 ? styles.plate5RightItem2 : styles.plate5RightItem3}`}>{row}</span>
                                                :
                                                <div style={{ margin: '-104px 0' }} key={indexs}>
                                                    {indexs === 0 &&
                                                        <div>
                                                            <span className='d-block' style={{ fontSize: '24px', color: '#121213', margin: '0 0 16px', fontWeight: 'bold' }}>顶部颜色自定义</span>
                                                            <div className='d-flex'>
                                                                {
                                                                    plate5List[this.state.plate5Index].plate5Custom?.map((ceo, indexC) => {
                                                                        return (
                                                                            <>
                                                                             {
                                                                                this.state.plate5Index === 0 ? 
                                                                                <div key={indexC}>
                                                                                    <div onMouseMove={() => { this.onCustom(indexC, ceo.image) }} key={indexC}
                                                                                        className={`${styles.plate5Custom} ${this.state.plate5CustomIndex === indexC ? styles.plate5CustomHover : ''}`}
                                                                                        style={{ background: ceo.background }}
                                                                                    >
                                                                                    </div>
                                                                                </div> 
                                                                                :
                                                                                <div key={indexC}>
                                                                                    <div onMouseMove={() => { this.onCustom(indexC, ceo.image) }} key={indexC}
                                                                                        className={`${styles.plate5Custom} ${this.state.plate5CustomIndex === indexC ? styles.plate5CustomHover1 : ''}`}
                                                                                        style={{ background: ceo.background }}
                                                                                    >
                                                                                    </div>
                                                                                </div>
                                                                             }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <span className='d-block' style={{ fontSize: '24px', color: '#121213', margin: '26px 0 16px', fontWeight: 'bold' }}>底部TAB自定义</span>
                                                            <div className='d-flex flex-column'>
                                                                {
                                                                    plate5CustomBottom.map((ceo1, indexd) => {
                                                                        return (
                                                                            <div key={indexd} className='d-flex align-items-center' style={{ marginBottom: '20px' }}>
                                                                                <span className='d-block' style={{ fontSize: '24px', color: '#333333', marginRight: '26px' }}>{ceo1.title}</span>
                                                                                {ceo1.icon.map((ceo2, indexe) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={indexe}
                                                                                            onMouseMove={() => { this.onCustomBtm(ceo2.id, ceo2.image) }}
                                                                                            className={`d-flex justify-content-center align-items-center ${styles.plate5CustomBtm} ${this.state.plate5CustomBtmIndex === ceo2.id ? styles.plate5CustomBtmActive : ''}`}
                                                                                            style={{ width: '60px', height: '60px' }}>
                                                                                            <img src={require(`../../asset/city_service_system/${ceo2.image}.png`)} style={{ width: '44px', height: '44px' }} alt=''></img>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>}
                                                    <span className={`d-block ${styles.plate5RightItem} ${indexs === 0 ? styles.plate5RightItem4 : styles.plate5RightItem2}`}>{row}</span>
                                                </div>
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
                {/**页面展示  板块6**/}
                <div style={{ margin: '100px 0 80px', background: '#FFFFFF' }}>
                    <Title title="页面展示"
                        text="PAGE DISPLAY"
                        width={710}
                        height={66} />
                    <div className="d-flex justify-content-center  align-items-center" style={{ marginBottom: '50px' }}>
                        {['跑腿页面', '外卖页面', '店铺页面'].map((item, index) => {
                            return (
                                <div className="d-flex  align-items-center" key={index}>
                                    <div onMouseMove={() => { this.slick6Index(index) }} style={{ width: '310px' }} className={classnames('home-plate2-btn1', 'd-flex', 'justify-content-center', 'align-items-center', { 'home-plate2-btn-active': this.state.plate6Index === index })}>
                                        {item}
                                    </div>
                                    {item !== '店铺页面' && <span className="home-plate2-span"></span>}
                                </div>
                            )
                        })}
                    </div>
                    <Carousel dots={false} ref={slider => (this.sliders = slider)}>
                        <div className={styles.plate6}>
                            {plate6List[this.state.plate6Index].data.map((item, index) => {
                                return (
                                    <div key={index} className={styles.plate6Item}>
                                        <img src={require(`../../asset/city_service_system/${item.icon}.png`)} style={{ width: '305px', height: '584px' }} alt=''></img>
                                        <span>{item.title}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </Carousel>
                </div>
                {/**趣来达同城生活O2O系统优势  板块7**/}
                <div style={{ margin: '100px 0', background: '#FFFFFF' }}>
                    <Title title="趣来达同城生活O2O系统优势"
                        text="SYSTEM ADVANTAGES"
                        width={710}
                        height={66} />
                    <div className="d-flex align-items-center justify-content-between" style={{ width: "1440px", margin: '0 auto' }}>
                        <div style={{ flex: 1 }}>
                            <div className="d-flex flex-column align-items-end">
                                <div className={styles.plate7} style={{ background: '#E9EFFB', color: "#4A7EE6" }}>
                                    低成本创业
                                    <img alt='' src={require(`../../asset/city_service_system/system-oto-modulesF-icon1.png`)} style={{ width: '30px', height: "30px", marginLeft: '7px' }} />
                                </div>
                                <p style={{ marginRight: '16px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>低风险创业项目，投入成本低，购买系统后提供强有力</p>
                                <p style={{ marginRight: '20px', marginBottom: '55px', color: '#707070', fontSize: '16px' }}>的技术服务支持，后期维护及功能升级全部免费</p>
                            </div>
                            <div className="d-flex flex-column align-items-end" style={{ width: '405px' }}>
                                <div className={styles.plate7} style={{ background: '#FAF5E4', color: "#F6BB07" }}>
                                    灵活项目搭配
                                    <img alt='' src={require(`../../asset/city_service_system/system-oto-modulesF-icon2.png`)} style={{ width: '30px', height: "30px", marginLeft: '7px' }} />
                                </div>
                                <p style={{ marginRight: '20px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>支持跑腿、外卖选择性运营，也可以同时运营，灵活项</p>
                                <p style={{ marginRight: '20px', marginBottom: '55px', color: '#707070', fontSize: '16px' }}>目处理全方位配合平台发展</p>
                            </div>
                            <div className="d-flex flex-column align-items-end">
                                <div className={styles.plate7} style={{ background: '#FBEFE6', color: "#FF791E" }}>
                                    多端口互联
                                    <img alt='' src={require(`../../asset/city_service_system/system-oto-modulesF-icon3.png`)} style={{ width: '30px', height: "30px", marginLeft: '7px' }} />
                                </div>
                                <p style={{ marginRight: '20px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>小程序、H5、APP、PC后台多端互联，无缝衔接用</p>
                                <p style={{ marginRight: '20px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>户、商户、骑手和平台运营者，较少交互，多渠道开展</p>
                            </div>
                        </div>
                        <img src={require(`../../asset/city_service_system/plate7Image.png`)} style={{ width: '500px', height: '400px', margin: '0 20px' }} alt=''></img>
                        <div style={{ flex: 1 }}>
                            <div className="d-flex flex-column ">
                                <div className={styles.plate7} style={{ background: '#E6F7F1', color: "#2BCF88" }}>
                                    <img alt='' src={require(`../../asset/city_service_system/system-oto-modulesF-icon4.png`)} style={{ width: '30px', height: "30px", marginRight: '7px' }} />
                                    强大功能加持
                                </div>
                                <p style={{ marginLeft: '20px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>完善的同城服务运营功能，丰富营销手段、人性化的骑手</p>
                                <p style={{ marginLeft: '20px', marginBottom: '55px', color: '#707070', fontSize: '16px' }}>奖惩、等级、管理制度与灵活计价处理</p>
                            </div>
                            <div className="d-flex flex-column" style={{ marginLeft: '40px' }}>
                                <div className={styles.plate7} style={{ background: '#E4F0FB', color: "#1887E6" }}>
                                    <img alt='' src={require(`../../asset/city_service_system/system-oto-modulesF-icon5.png`)} style={{ width: '30px', height: "30px", marginRight: '7px' }} />
                                    保姆式扶持
                                </div>
                                <p style={{ marginLeft: '20px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>除提供软件技术服务以外，安排专人售后一对一远程服</p>
                                <p style={{ marginLeft: '20px', marginBottom: '55px', color: '#707070', fontSize: '16px' }}>务，提供运营方案、收益指导、协议模板等文件材料</p>
                            </div>
                            <div className="d-flex flex-column">
                                <div className={styles.plate7} style={{ background: '#FAF5E4', color: "#F6BB07" }}>
                                    <img alt='' src={require(`../../asset/city_service_system/system-oto-modulesF-icon6.png`)} style={{ width: '30px', height: "30px", marginRight: '7px' }} />
                                    数据安全
                                </div>
                                <p style={{ marginLeft: '20px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>采用阿里云服务大品牌品质保障,多重防护客户数据安</p>
                                <p style={{ marginLeft: '20px', marginBottom: '0px', color: '#707070', fontSize: '16px' }}>全，严谨加密处理,为客户做好安全护盾</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CityServiceSystem;