import React from 'react';
import { Row, Col } from 'antd';
import notJust from '../asset/notJust.png';

const style = {
  height: '42px',
  fontSize: '53px',
  fontWeight: 700,
  opacity: 0.08,
  textTransform: 'Uppercase',
  position: 'absolute',
  top: 9,
  left: 0,
  right: 0,
  margin: 'auto',
  zIndex:-1
}

function Title(props) {
  return (
    <Row>
      <Col span={24} className="text-center global-title-main animated fadeInDown" style={{marginBottom:props.marginBottom}}>
        <span className="d-block font-bold global-title" style={{color: props.titleColor}}>
          {props.title}{props.spanColor && <span style={{color:'#F6BB07'}}>{props.spanColor}</span>}
        </span>
        <span className="d-block title-line-between"></span> 
        <div className="d-flex jscen">
          <div>
            {props.dec && <span className="d-block global-small-title" style={{color:props.decColor ? props.decColor : '#707070' }}>{props.dec}</span>}
            {props.decs && <span className="d-block global-small-title" style={{color:props.decColor ? props.decColor : '#707070',marginTop:'0px' }}>{props.decs}</span>}
          </div>
          {
            props.text ? <span style={{...style,color:props.textColor ? props.textColor : '#121213'}}>{props.text}</span> : <img style={{width: `${props.width}px`, height: `${props.height}px`, marginTop: props.dec ? '-230px' : '-130px',position:'relative',zIndex:'-1'}} src={props.imgUrl} alt="globalImg"/>
          }
        </div>
      </Col>
    </Row>
  );
}

Title.defaultProps = {
  title: '默认title',
  titleColor: '#121213',
  textColor:'',
  imgUrl: notJust,
  width: 300,
  height: 80,
  dec: '',
  marginBottom: 60
}

export default Title;
