import axios from 'axios';

// 获取新闻列表
export async function newsList(newsType, pageNum, keyword, isOut) {
  const formData = new FormData();
  formData.append('news_type', newsType);
  formData.append('page', pageNum);
  formData.append('keyword', keyword);
  const result = axios.post('/api/Company/getPageList', formData).catch(result => result)
  return result
}

// 获取热门新闻
export async function getHotNewsList() {
  return axios.get('api/Company/getHotNewsList')
}