import React from 'react';
import { Helmet } from 'react-helmet';
import { Tree, Icon, Row, Col } from 'antd';
import styles from '../../css/manuell.module.scss';
import { data } from '../../components/manuell/data';

let pageTimer = '' //停留时间


class manuell extends React.Component {
  state = {
    id: '001'
  }

  componentWillMount() {
    this.props.onShow(false)
    if (window.location.search) {
      const arr = window.location.search.split('=')
      console.log(arr, window.location.search)
      this.setState({ id: arr[1] })
      // 定位
      setTimeout(() => {
        console.log(document.getElementsByClassName('ant-tree-treenode-selected'))
        if (document.getElementsByClassName('ant-tree-treenode-selected').length > 0) {
          document.getElementsByClassName('ant-tree-treenode-selected')[0].scrollIntoView()
        }
      }, 0);
    }
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'manuell'
    }, 0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer, 'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'manuell',
        time: tempTimer
      }, 0)
    }
  }

  componentDidUpdate() {
    document.getElementById("content").scrollTo({ top: 0, behavior: "smooth" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  render() {
    return (
      <div style={{ background: '#F8F8F8' }}>
        <Helmet>
          <title>趣来达-跑腿派单服务软件|城市配送系统|同城配送解决方案</title>
          <meta name="Description" content="趣来达同城配送是专业的城市、县城、校园跑腿系统,配送骑士APP+商户小程序+配送团队管理端+无线打印机接单,开放第三方配送接口,接收各大电商平台、外卖平台订单。" />
          <meta name="Keywords" content="同城跑腿软件,配送管理软件,城市配送系统,跑腿服务软件,跑腿派单,快递货运系统,微信派单,物流同城配送跑腿解决方案" />
        </Helmet>
        <Row className={`d-flex  ${styles.treeBox}`}>
          <Col span={6} className={`d-flex flex-column ${styles.katalog}`}
          >
            <h2 className={styles.katalogTitle}>目录</h2>
            <Tree
              defaultExpandAll
              className={styles.antTree}
              showLine
              switcherIcon={<Icon type="down" />}
              defaultSelectedKeys={['001']}
              selectedKeys={[this.state.id]}
              onSelect={(e) => {
                console.log(e);
                if (e.length > 0 && e[0].length < 3) return;
                this.setState({ id: e.length ? e[0] : this.state.id });
                window.history.replaceState(null, null, `?id=${e.length ? e[0] : this.state.id}`)
              }
              }
              treeData={data}
            >
            </Tree>
          </Col>
          <Col span={18} className={styles.content} id="content" style={{ background: '#ffffff' }}>
            {data.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.children.find(son => this.state.id === son.key)?.content}
                </React.Fragment>
              )
            })}
          </Col>
        </Row>
      </div>
    )
  }
}

export default manuell;