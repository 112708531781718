import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/campus_platform_system.module.scss';
import { Helmet } from 'react-helmet';
// components
import classnames from 'classnames';
import Title from '../../components/Title';

// 板块二数据
const plate2List = [
  {
    title: '中转配送',
    cont: '通过设立中转站，实现分段配送，保障订单快速、及时送达，且能送餐到寝室，无敌服务质量。'
  },
  {
    title: '预设地址',
    cont: '支持后台设置预设地址，用户下单可直接选择，快速完成下单，餐将送到指定位置，用户自取。'
  },
  {
    title: '扫码接单',
    cont: '骑手可通过APP扫码接单，直接录入订单信息，快速便捷，有效提升校园配送效率。'
  },
  {
    title: '自选配送池',
    cont: '调度后台可设置多个配送池，针对男女寝室设立男骑手与女骑手，用户下单根据自己位置自选配送池。'
  }
]

const plate3 = {
  dataLeft: [
    {
      id: 1, //用来标记图片
      title: '邀请有礼'
    },
    {
      id: 2, //用来标记图片
      title: '积分商城'
    },
    {
      id: 3, //用来标记图片
      title: '超级会员'
    },
    {
      id: 4, //用来标记图片
      title: '天天特价'
    }
  ],
  dataRight: [
    {
      id: 5, //用来标记图片
      title: '拼好饭'
    },
    {
      id: 6, //用来标记图片
      title: '第二件半价'
    },
    {
      id: 7, //用来标记图片
      title: '本地团购'
    },
    {
      id: 8, //用来标记图片
      title: '优惠券'
    }
  ],
  iconArr: [
    {
      title: '分享有礼',
      icon: 'plate3Icon1'
    },
    {
      title: '会员积分',
      icon: 'plate3Icon2'
    },
    {
      title: '满减活动',
      icon: 'plate3Icon3'
    },
    {
      title: '减配送费',
      icon: 'plate3Icon4'
    },
    {
      title: '营销签到',
      icon: 'plate3Icon5'
    },
    {
      title: '弹窗广告',
      icon: 'plate3Icon6'
    },
    {
      title: '新客立减',
      icon: 'plate3Icon7'
    },
    {
      title: '折扣商品',
      icon: 'plate3Icon8'
    },
    {
      title: '随机购',
      icon: 'plate3Icon9'
    },
    {
      title: '组合券',
      icon: 'plate3Icon10'
    },
    {
      title: '店铺红包',
      icon: 'plate3Icon11'
    },
    {
      title: '折扣商品',
      icon: 'plate3Icon12'
    },
    {
      title: '指定优惠券',
      icon: 'plate3Icon13'
    },
    {
      title: '开屏广告',
      icon: 'plate3Icon14'
    },
    {
      title: '积分兑换',
      icon: 'plate3Icon15'
    }
  ]
}
const plate4List = [
  {
    title: '广东省某高校外卖平台',
    cont: '通过趣来达系统打造校园规模的外卖平台,整合学校食堂、周边餐饮商家、奶茶店、蛋糕店,打造安全的校园平台,日均订单量5000+。',
    image: 'plate4List1'
  },
  {
    title: '西安市某校园生活圈',
    cont: '学生创业者通过系统打造校园掌上生活平台，整合了餐饮外卖、校园团购、短途运输、表白墙等服务类型，日订单2000+。',
    image: 'plate4List2'
  },
  {
    title: '广西某校园外卖平台',
    cont: '整合了包括食堂在内的校内外餐饮商家，提供线上点餐、配送一站式便捷订餐服务，月流水几十万。',
    image: 'plate4List3'
  }
]

const plate5List = [
  {
    title: '原生开发',
    cont: '趣来达校园O2O系统是由成都壹立科技有限公司原生开发的软件，数十人的研发团队，专业架构师率队研发，保持高频的更新迭代速度，为校园创业者保驾护航。',
    image: 'plate5List1'
  },
  {
    title: '系统稳定',
    cont: '高级防护守护平台稳定运行，专业运维团队7*24h实时在线程序排查，全力保障校园创业者随时稳定运营，提供安心、靠谱的生活平台系统。',
    image: 'plate5List2'
  },
  {
    title: '优质售后',
    cont: '签约合作后，趣来达会提供专人一对一售后进行系统对接，最快3天保姆式平台搭建，提供操作文档与操作视频，5*12h在线解决问题，远程指导。',
    image: 'plate5List3'
  }
]

let pageTimer = '' //停留时间

class CampusPlatformSystem extends React.Component {
  state = {
    phone: '',
    baneerIndex: 0,
    plate3Index: 1,
  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'campus'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'campus',
        time: tempTimer
      },0)
    }
  }

  slickGoToIndex = (id) => {
    this.setState({
      plate3Index: id
    })
  }
  render() {
    return (
      <div className="w-100">
        <Helmet>
          <title>趣来达-校园外卖小程序|食堂外卖|外卖平台系统</title>
          <meta name="Description" content="趣来达校园外卖小程序针对校园区域有限、人口密度高度集中的市场环境，以及男女不能混寝配送导致送货延迟或骑手无法送餐上门的情况，趣来达校园外卖平台聚合丰富的校园消费场景，三天快速完成搭建，致力于助力校园创业者打造掌上智慧校园平台。" />
          <meta name="Keywords" content="校园外卖平台,校园外卖小程序,食堂外卖,外卖平台系统"/>
        </Helmet>
        <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
          <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
            <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
              <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>聚合丰富的<span style={{ color: '#F6BB07' }}>校园消费场景</span>，三天快速完成搭建</div>
              <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>致力于助力校园创业者打造<span style={{ color: '#F6BB07' }}>掌上智慧校园平台</span></span>
              <div className='Carousel-button d-flex animated fadeInRight'>
                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({phone:e.target.value})}} placeholder='请输入您的手机号' />
                <div onClick={()=> {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.com',
                    page: 'campus',
                    button: 'onTrial'
                  },0);
                  this.props.submit(this.state.phone)
                }} className='Carousel-btn'>立即试用</div>
              </div>
            </div>
          </div>
        </Carousel>
        {/**解决方案  板块2**/}
        <div style={{ background: '#FFFFFF', margin: '100px 0 80px' }}>
          <Title title="解决方案"
            text="SOLUTION"
            width={710}
            height={66} />
          <div className={styles.plate2}>
            <span className='d-block' style={{ fontSize: '30px', color: '#121213', fontWeight: '700', textAlign: 'center' }}>校园市场特性</span>
            <span className='d-block' style={{ fontSize: '20px', color: '#707070', textAlign: 'center', marginTop: '20px' }}>
              针对区域有限，人口密度高度集中的市场环境，以及男女不能混寝室进入，导致送货延迟或骑手无法送餐上门的情况。<br />
              趣来达校园外卖平台系统提供了一系列解决方案。
            </span>
            <img alt='' src={require(`../../asset/campus_platform_system/plate2Image.png`)} className='d-block' style={{ width: '1280px', height: '600px', margin: '50px 0' }} />
            <div className='d-flex justify-content-between flex-wrap align-content-center'>
              {plate2List.map((item, index) => {
                return (
                  <div key={index} className={styles.plate2Item}>
                    <span className={styles.plate2ItemTitle}>{item.title}</span>
                    <div style={{ fontSize: '20px', color: '#707070' }}>{item.cont}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/**营销功能介绍  板块3**/}
        <div style={{ background: '#F7F9FC', padding: '100px 0', marginBottom: '-100px' }}>
          <Title title="营销功能介绍"
            text="INTRODUCTION TO MARKETING FUNCTIONS"
            width={710}
            height={66} />
          <div className={styles.plate3}>
            <div className={styles.plate3Top}>
              <div className={styles.plate3ItemBox}>
                {plate3.dataLeft.map((item, index) => {
                  return (
                    <span key={index} onMouseMove={() => { this.slickGoToIndex(item.id) }}
                      className={this.state.plate3Index === item.id ? `${styles.plate3Item} ${styles.plate3ItemActive}` : `${styles.plate3Item}`}
                    >{item.title}</span>
                  )
                })}
              </div>
              <img src={require(`../../asset/campus_platform_system/plate3Image${this.state.plate3Index}.png`)} style={{ width: '330px', height: '660px' }} alt=''></img>
              <div className={styles.plate3ItemBox}>
                {plate3.dataRight.map((item, index) => {
                  return (
                    <span key={index} onMouseMove={() => { this.slickGoToIndex(item.id) }}
                      className={this.state.plate3Index === item.id ? `${styles.plate3Item} ${styles.plate3ItemActive}` : `${styles.plate3Item}`}
                    >{item.title}</span>
                  )
                })}
              </div>
            </div>
            <div className={styles.plate3Center}>
              {plate3.iconArr.map((item, index) => {
                return (
                  <div key={index} className={styles.plate3IConItem}>
                    <img src={require(`../../asset/campus_platform_system/${item.icon}.png`)} style={{ width: '100px', height: '100px' }} alt=''></img>
                    <span >{item.title}</span>
                  </div>
                )
              })}
            </div>
            <div className={styles.plate3Btn} onClick={() => {
              this.props.onVisitClick({
                type: 3,
                domain: 'www.qolai.com',
                page: 'campus',
                button: 'moreFun'
              },0);
              this.props.showDialog()
            }} style={{ cursor: 'pointer' }}>查看更多功能 <span style={{ fontWeight: '700' }}>More+</span></div>
          </div>
        </div>
        {/**案例分析  板块4**/}
        <div style={{ background: '#FFFFFF', margin: '100px 0' }}>
          <Title title="案例分析"
            text="CASE ANALYSIS"
            width={710}
            height={66} />
          <div className={styles.plate4}>
            {plate4List.map((item, index) => {
              return (
                <div className={styles.plate4Item} key={index}>
                  <img alt='' src={require(`../../asset/campus_platform_system/${item.image}.png`)} style={{ width: '100%', height: '158px' }} />
                  <div className={styles.plate4ItemTitle}>{item.title}</div>
                  <div className={styles.plate4ItemCont}>{item.cont}</div>
                </div>
              )
            })}
          </div>
        </div>
        {/**系统优势  板块5**/}
        <div style={{ background: '#F7F9FC', padding: '100px 0', marginBottom: '-100px' }}>
          <Title title="系统优势"
            text="SYSTEM ADVANTAGES"
            width={710}
            height={66} />
          <div className={styles.plate5}>
            {plate5List.map((item, index) => {
              return (
                <div className={styles.plate5Item} key={index}>
                  <img alt='' src={require(`../../asset/campus_platform_system/${item.image}.png`)} style={{ width: '100px', height: '100px' }} />
                  <div className={styles.plate5ItemTitle}>{item.title}</div>
                  <div className={styles.plate5ItemCont}>{item.cont}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default CampusPlatformSystem;