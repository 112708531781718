import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Dialog from 'element-react/dist/npm/es6/src/dialog';
import 'element-theme-default';
import { Select, message, Spin  } from 'antd';
// apis
import { submit,saveOfficialFrom,visitClick } from './apis/App';
import axios from 'axios';

//  components
import Header from './components/Header';
import Footer from './components/Footer';
import Side from './components/Side';

import Home from './containers/index/Home';
import Customized from './containers/index/custom';
import CaseList from './containers/index/case';
import Information from './containers/index/Information';
import About from './containers/index/About';
import InformationDetail from './containers/index/information_detail';
import TakeOutFood from './containers/index/food';
import CampusDistribution from './containers/index/distribution';
import ErrandDelivery from './containers/index/errands';
import CityServiceSystem from './containers/index/Service';
import CampusPlatformSystem from './containers/index/campus';
import SiteJoining from './containers/index/sjoining';
import PlatformOperation from './containers/index/platform';
import OfflineGroupPurchase from './containers/index/group';
import InformationDelivery from './containers/index/copy';
import DownloadCenter from './containers/index/download';
import Service from './containers/index/serviceid';
import Manuell from './containers/index/manuell';
import Self from './containers/index/self';



const { Option } = Select;
const key = 'updatable';

const options = [{
    value: '1',
    label: '案例 演示及产品资料'
}, {
    value: '2',
    label: '详细报价'
}, {
    value: '3',
    label: '其他需求'
}]

class App extends React.Component {
  state = {
    isShowDialog: false,
    name: '',
    intention: '1',
    phone: '',
    weChatNum: '',
    isShow:true
  }
  
  componentDidMount() {
    message.config({
      top: 100,
      duration: 2
    });
  }

  showDialog = () => {
    this.setState({
      isShowDialog: true
    });
  };

  onShow = (e) => {
    this.setState({
      isShow: e
    });
  }


  // 官网访问点击
  async onVisitClick(data, onSuccess) {
    let hide = null
    if (onSuccess !== 0) {
      hide = message.loading('', 0)
    }
    const parameter = {
      ...data
    }
    const res = await visitClick(parameter)
    hide && hide();
    if (res.code !== 200) {
      // message.error('请重试！')
      return;
    }
    onSuccess && onSuccess();
  }


  submit = async(phone) => {
    let data = {
      phone: phone,
      type: 4
    }
    if(phone.length < 11 || phone === ''|| !(/^1[3456789]\d{9}$/.test(phone))){
      message.warning('请输入正确的手机号码')
      return
    }
    const hide = message.loading('提交中...');
    const Result = await saveOfficialFrom(data)
    hide()
    console.log(Result)
    if (Result.code !== 200){
      message.error( Result.message || '提交失败');
    }else {
      message.success({ content: '提交成功!', key, duration: 2 });
    }
  }
a
  submitForm = async (dialogFormVisible) => {
    const phoneExp = /^1[3456789]\d{9}$/;
    const {name, intention, phone, weChatNum} = this.state;
    if (phone === '' || !phoneExp.test(phone)) {
      message.error('请输入正确的手机号码');
      return
    }

    const result = await submit(name, intention, phone, weChatNum)
    console.log(result)
    if (result.code === 200) {
      message.success('提交成功！')
      this.setState({
        isShowDialog: false
      });
    } else {
      message.error('提交失败！')
      this.setState({
        isShowDialog: false
      });
    }
  }

  render() {
    return (
      <Router>
        <Header {...this.props} showDialog={this.showDialog} onShow={this.onShow} onVisitClick={this.onVisitClick}/>
        <Switch>
          <Route exact path="/" render={() => <Home {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>} />
          <Route exact path="/custom" render={() => <Customized {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/case" render={() => <CaseList {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/Information" render={() => <Information {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/About" render={() => <About {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/information_detail" render={() => <InformationDetail {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/food" render={() => <TakeOutFood {...this.props} submit={this.submit} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/distribution" render={() => <CampusDistribution {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/errands" render={() => <ErrandDelivery {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/service" render={() => <CityServiceSystem {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/campus" render={() => <CampusPlatformSystem {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/sjoining" render={() => <SiteJoining {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/platform" render={() => <PlatformOperation {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/group" render={() => <OfflineGroupPurchase {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/copy" render={() => <InformationDelivery {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/download" render={() => <DownloadCenter {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/serviceid" render={() => <Service {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/manuell" render={() => <Manuell {...this.props} onShow={this.onShow} onVisitClick={this.onVisitClick} />}/>
          <Route exact path="/self" render={() => <Self {...this.props} onShow={this.onShow} submit={this.submit} onVisitClick={this.onVisitClick} />}/>
        </Switch>
        <Side showDialog={this.showDialog}/>
        {
          this.state.isShow && <Footer {...this.props} showDialog={this.showDialog} submit={this.submit} onVisitClick={this.onVisitClick}/>
        }
        <Dialog
          visible={this.state.isShowDialog}
          
          onCancel={() => {this.setState({isShowDialog: !this.state.isShowDialog})}}
          size="tiny"
        >
          <Dialog.Body style={{position:'relative'}}>
            <div>
              <h5 className="form-title">申请试用</h5>
              <div className="form-contacts ">
                <label>联系人</label>
                <input type="text"  placeholder="请填写您的姓名" value={this.state.name} onChange={(e) => {this.setState({name: e.currentTarget.value})}}/>
              </div>
              <div className="form-contacts">
                <label>您的需求</label>
                <Select defaultValue="请选择" value={this.state.intention} onChange={value => {this.setState({intention: value})}}>
                  {
                    options.map((item, index) => {
                      return (
                        <Option key={index} value={item.value}>{item.label}</Option>
                      )
                    })
                  }
                </Select>
              </div>
              <div className="form-contacts ">
                <label>手机号码</label>
                <div>
                  <span style={{fontWeight: '500', marginRight: 10}}>+86</span>
                  <input maxLength="11" type="text"  placeholder="请填写您的手机号码" value={this.state.phone} onChange={(e) => {this.setState({phone: e.currentTarget.value})}}/>
                </div>
              </div>
              <div className="form-contacts ">
                <label>微信</label>
                <input type="text"  placeholder="请填写微信号码" value={this.state.weChatNum} onChange={(e) => {this.setState({weChatNum: e.currentTarget.value})}}/>
              </div>
              <button className="form-btn-OnTrial" onClick={(dialogFormVisible) => {this.submitForm(dialogFormVisible)}}>立即领取</button>
              <div style={{marginTop: '10px',textAlign: 'center'}}>很着急 立即拨打或加V:18771040879</div>
              <div className={` ${this.state.isShowDialog === true ?  'loading-box-none' : 'loading-box'}`}>
                <Spin size="large" />
              </div>
            </div>
          </Dialog.Body>
        </Dialog>
      </Router>
    );
  }
}

export default App;
