import React from 'react';

// 路由视图组件
import Home from '../containers/index/Home';
import Customized from '../containers/index/custom';
import CaseList from '../containers/index/case';
import Information from '../containers/index/Information';
import About from '../containers/index/About';
import InformationDetail from '../containers/index/information_detail';
import TakeOutFood from '../containers/index/food';
import CampusDistribution from '../containers/index/distribution';
import ErrandDelivery from '../containers/index/errands';
import CityServiceSystem from '../containers/index/Service';
import CampusPlatformSystem from '../containers/index/campus';
import SiteJoining from '../containers/index/sjoining';
import PlatformOperation from '../containers/index/platform';
import OfflineGroupPurchase from '../containers/index/group';
import InformationDelivery from '../containers/index/copy';
import DownloadCenter from '../containers/index/download';
import Service from '../containers/index/serviceid';
import Self from '../containers/index/self';



// 首页--板块2的图片
import homePlate2Icon1 from '../asset/home_phone1.png';
import homePlate2Icon2 from '../asset/home_phone2.png';
import homePlate2Icon3 from '../asset/home_phone3.png';
import homePlate2Icon4 from '../asset/home_phone4.png';

// router
export const routerArr = [{
  path: '/',
  render: props => <Home {...props} />
}, {
  path: 'custom',
  render: props => <Customized {...props} />
}, {
  path: 'case',
  render: props => <CaseList {...props} />
}, {
  path: 'information',
  render: props => <Information {...props} />
}, {
  path: 'about',
  render: props => <About {...props} />
}, {
  path: 'information_detail',
  render: props => <InformationDetail {...props} />
}, {
  path: 'food',
  render: props => <TakeOutFood {...props} />
}, {
  path: 'distribution',
  render: props => <CampusDistribution {...props} />
}, {
  path: 'errands',
  render: props => <ErrandDelivery {...props} />
}, {
  path: 'service',
  render: props => <CityServiceSystem {...props} />
}, {
  path: 'campus',
  render: props => <CampusPlatformSystem {...props} />
}, {
  path: 'sjoining',
  render: props => <SiteJoining {...props} />
}, {
  path: 'platform',
  render: props => <PlatformOperation {...props} />
}, {
  path: 'group',
  render: props => <OfflineGroupPurchase {...props} />
}, {
  path: 'copy',
  render: props => <InformationDelivery {...props} />
}, {
  path: "download",
  render: props => <DownloadCenter {...props} />
}, {
  path: "serviceid",
  render: props => <Service {...props} />
}, {
  path: "self",
  render: props => <Self {...props} />
}]

// 导航
export const navBarArr = [{
  title: '首页',
  path: '/'
}, {
  title: '产品中心',
  list: [
    {
      title: '同城',
      icon: 'barIcon1',
      arr: [
        {
          title: '自配送系统',
          path: '/self',
          tipType:"hot",
          id:'013'
        },
        {
          title: '跑腿配送系统',
          path: '/errands',
          id:'010'
        },
        {
          title: '同城外卖平台系统',
          path: '/food',
          tipType:"hot",
          id:'011'
        },
        {
          title: '调度配送系统',
          path: '/service',
          tipType:"hot",
          id:'012'
        }
      ]
    },
    {
      title: '校园',
      icon: 'barIcon2',
      arr: [
        {
          title: '校园跑腿配送系统',
          path: '/distribution',
          id:'021'
        },
        {
          title: '校园外卖服务系统',
          path: '/campus',
          id:'022'
        }
      ]
    },
    {
      title: '平台',
      icon: 'barIcon3',
      arr: [
        {
          title: '多站点加盟',
          path: '/sjoining',
          id:'030'
        },
        {
          title: '多平台运营',
          tipType:"hot",
          path: '/platform',
          id:'031'
        },
        {
          title: '线下团购系统',
          tipType:"new",
          path: '/group',
          id:'032'
        },
        {
          title: '信息发布系统',
          tipType:"new",
          path: '/copy',
          id:'033'
        }
      ]
    },
    {
      title: '服务',
      icon: 'barIcon4',
      arr:[
        {
          title: "SaaS租用",
          path: "/serviceid?id=1",
          tipType:"hot",
          id:'040'
        },
        {
          title: "OEM品牌定制",
          path: "/serviceid?id=3",
          tipType:"hot",
          id:'041'
        },
        {
          title: "特殊功能定制",
          path: "/serviceid?id=4",
          id:'042'
        },
        {
          title: "独立部署",
          path: "/serviceid?id=1",
          tipType:"new",
          id:'043'
        }
      ]
    },
    {
      title: '下载',
      icon: 'barIcon5',
      arr:[
        {
          title: "用户端体验",
          path: "/download?id=1",
          tipType:"test",
          id:'050'
        },
        {
          title: "商家APP",
          path: "/download?id=2",
          id:'051'
        },
        {
          title: "商户后台",
          path: "/download?id=3",
          id:'052'
        },
        {
          title: "骑手APP",
          path: "/download?id=4",
          id:'053'
        }
      ]
    },
  ]
}, {
  title: '软件定制',
  path: '/custom'
}, {
  title: '客户案例',
  path: '/case'
}, {
  title: '行业资讯',
  path: '/information'
},
{
  title: '关于我们',
  path: '/about'
}]


// 首页
export const homeArr = [{
  title: '个性化平台打造高级用户体验',
  img: homePlate2Icon4,
  list: [
    { title: '用户小程序+H5快速入口，无需下载', icon: 'ic_search' },
    { title: '精准地图式定位，天气温度显示', icon: 'ic_loction' },
    { title: '显示附近骑手数量，预计接单时间', icon: 'ic_time' },
    { title: '精选美食与商家，快速判定优质店铺下单', icon: 'ic_store' }
  ],
  index: 0,
  path: '/errands#distribution'
}, {
  title: '丰富营销活动拉动用户裂变式增长',
  img: homePlate2Icon2,
  list: [
    { title: '新客立减，针对新客户推出单独的优惠减免', icon: 'ic_ free' },
    { title: '邀请有礼，邀请新客户下单可获取佣金并提现', icon: 'ic_gift' },
    { title: '团购活动，店铺可推出团购特价商品引流顾客到线下', icon: 'ic_ic_video' },
    { title: '用户分享，店铺收藏与转发分享', icon: 'ic_share' }
  ],
  path: '/food#logical',
  index: 1,
}, {
  title: '海量玩法增强平台用户黏性',
  img: homePlate2Icon3,
  list: [
    { title: '优惠券营销，薄利多销折扣吸引', icon: 'ic_sale' },
    { title: '商品满减与立减配送费，引发多次消费', icon: 'ic_loction(1)' },
    { title: '会员红包，锁住平台/店铺用户反复消费', icon: 'ic_Pay' },
    { title: '余额充值赠送，锁住用户长期消费', icon: 'ic_address' }
  ],
  path: '/food#highlights',
  index: 1,
}, {
  title: '优质系统提升用户平台体验',
  img: homePlate2Icon1,
  list: [
    { title: '便捷小程序，一次使用永久留存', icon: 'ic_foever' },
    { title: '优质系统，提升用户使用好感', icon: 'ic_system' },
    { title: 'H5下单关注公众号，粉丝锁定精准推送活动', icon: 'ic_wx' },
  ],
  path: '/food#common',
  index: 1,
}]


export const homeSystem = [
  { img: 'img_life', img2: 'img_life2', icon: 'ic_life', title: '趣来达-同城生活系统', btn: '查看产品详情 More+', content: '打造本地万能生活服务平台，搭建集线上点餐、接单、打印、调度、配送功能于一体的同城生活服务平台，成熟的O2O系统，轻松接轨“互联网+”', path: '/errands' },
  { img: 'img_delivery', img2: 'img_delivery2', icon: 'ic_delivery', title: '趣来达-跑腿配送系统', btn: '查看产品详情 More+', content: '打通同城跑腿配送全流程，支持帮我买、帮我送、代排队、帮我办等跑腿服务，全套解决方案包括跑腿系统用户端、商家端、配送端以及强大的后台调度管理系统。', path: '/food' }
]

export const homeBlateThree = [{
  list: [{
    icon: 'ic_services1',
    title: '跑腿帮办',
    title2: 'Running errands'
  }, {
    icon: 'ic_services2',
    title: '快递送取',
    title2: 'Express take to send'
  }, {
    icon: 'ic_services3',
    title: '同城代办',
    title2: 'City do STH for sb'
  }]
}, {
  list: [{
    icon: 'ic_services4',
    title: '餐饮外卖',
    title2: 'Catering takeout'
  }, {
    icon: 'ic_services5',
    title: '生鲜配送',
    title2: 'Fresh distribution'
  }, {
    icon: 'ic_services6',
    title: '商超联盟',
    title2: 'Businness union'
  }]
}, {
  list: [{
    icon: 'ic_services7',
    title: '线上购物',
    title2: 'Online shopping'
  }, {
    icon: 'ic_services8',
    title: '上门服务',
    title2: 'Visiting service'
  }, {
    icon: 'ic_services9',
    title: '附近零售',
    title2: 'Near the retail'
  }]
}]

export const phoneMask = [{
  title: '营销功能提升用户量',
  dec: '跑腿配送系统内设置优惠券等营销功能，刺激用户裂变营销',
  img: 'phone1'
}, {
  title: '配送员实名认证',
  dec: '跑腿配送平台内所有配送员必须实名认证，官方审核上岗',
  img: 'phone2'
}, {
  title: '群抢单、指派单',
  dec: '配送系统内多种派单、发单的模式并行，确保订单及时响应进行',
  img: 'phone3'
}, {
  title: '奖惩并行，规范平台',
  dec: '对配送员实行奖惩制度，规范平台运行，确保无安全、财产纠纷',
  img: 'phone4'
}, {
  title: '订单进程实时推送',
  dec: '一系列跑腿配送进程，均在系统内实时推送给用户、商家、配送员',
  img: 'phone5'
}, {
  title: '订单行程实时监控',
  dec: '配送员接单情况、所在区域等信息均详细显示，方便客户查看进度',
  img: 'phone6'
}, {
  title: '后台跑腿调度系统',
  dec: '跑腿智能调度系统智能处理订单派发情况，确保跑腿平台良好运转',
  img: 'phone7'
}, {
  title: '所有订单管理追溯',
  dec: '跑腿配送系统内所有订单可永久追溯，数据永久留存',
  img: 'phone8'
}]

// 产品介绍
export const deliveryArr = [{
  imgUrl: 'icon1.png',
  describe: '美食生鲜百货，想购就购足不出户 购遍全城 。一键下单，快速送达',
  text: '代买'
}, {
  imgUrl: 'icon2.png',
  describe: '没时间，不想出门，趣来达一下在线下单，快速上门取件',
  text: '代送'
}, {
  imgUrl: 'icon3.png',
  describe: '取文件、钥匙、发票或其他物品，无须亲力亲为，随时随地享受服务。',
  text: '代取'
}, {
  imgUrl: 'icon4.png',
  describe: '排队、抢票、等号.......琐事太多没时间处理？一键下单，代办全搞定',
  text: '代办'
}]

export const iconArrOne = [{
  title: '地图指派',
  icon: 'icon3'
}, {
  title: '区域配送',
  icon: 'icon4'
}, {
  title: '智能群抢单',
  icon: 'icon5'
}, {
  title: '送单计价',
  icon: 'icon6'
}, {
  title: '配送时长',
  icon: 'icon7'
}, {
  title: '位置监控',
  icon: 'icon8'
}]

export const iconArrTwo = [{
  title: '配送员权限',
  icon: 'icon12'
}, {
  title: '发单计价',
  icon: 'icon9'
}, {
  title: '订单统计',
  icon: 'icon11'
}, {
  title: '智能调度',
  icon: 'icon10'
}, {
  title: '系统通知',
  icon: 'icon1'
}]


// 软件定制
export const customizedBlateFour = [{
  list: [{
    title: '校园跑腿配送',
    icon: 'blate1'
  }, {
    title: '跑腿公司',
    icon: 'blate2'
  }, {
    title: '同城区域配送',
    icon: 'blate3'
  }]
}, {
  list: [{
    title: '快递公司',
    icon: 'blate4'
  }, {
    title: '同城生活服务平台',
    icon: 'blate5'
  }, {
    title: '本地电商平台',
    icon: 'blate6'
  }]
}]

export const customizedBlateThree = [{
  title: '专业性',
  dec: '壹立科技专注于互联网信息技术及软件系统技术服务，旨在为各大行业领域及商家提供优质技术产品服务，保障解决各商家技术难题，核心应用于商家营销推广、吸粉引流的运营场景里，让平台、让商家拥有获取更多流量的技术奠基能力',
  icon: 'icon7'
}, {
  title: '灵活性',
  dec: '壹立科技始终提供高效率、稳定化、个性化的技术服务，为客户提供全新的和创新性的途径来吸引消费者，同时提高在基础技术服务和定制服务的灵活性',
  icon: 'icon8'
}, {
  title: '定制化',
  dec: '建立在壹立科技成熟的软件技术开发流程之上，并通过不断的迭代更新之后，定制出客户真正需要、真正喜欢的个性化产品',
  icon: 'icon9'
}]

// 产品介绍 ---- 趣来达优购
export const shoppingPlate2 = [{
  icon: 'plate2_icon1',
  dec: 'PC管理端+微信公众号+小程序，资源数据互通，操作灵活，轻量级应用'
}, {
  icon: 'plate2_icon2',
  dec: '多元互动营销功能赋能商城：拼团、砍价、秒杀、积分、会员卡等丰富营销策略提升用户黏性'
}, {
  icon: 'plate2_icon3',
  dec: '提供系统源码，商家拥有源码，不仅掌握了商城的运营权，更能完全操控后期开发'
}, {
  icon: 'plate2_icon4',
  dec: '支持定制开发，独立部署商户专属品牌的电商系统，全面满足商户品牌塑造'
}, {
  icon: 'plate2_icon5',
  dec: '快速搭建平台，趣来达优购是一套成熟的电商系统，授权即可商业运营，省去开发费用'
}, {
  icon: 'plate2_icon6',
  dec: '低成本打造线上商城，让中小型企业快速从移动互联网时代迅速崛起'
},]

export const plate4Icon = {
  list1: [{
    icon: 'plate4_icon1',
    title: '日化用品'
  }, {
    icon: 'plate4_icon2',
    title: '鞋服门店'
  }, {
    icon: 'plate4_icon3',
    title: '餐饮行业'
  }, {
    icon: 'plate4_icon4',
    title: '生鲜水果'
  }],
  list2: [{
    icon: 'plate4_icon5',
    title: '母婴用品'
  }, {
    icon: 'plate4_icon6',
    title: '教育培训'
  }, {
    icon: 'plate4_icon7',
    title: '医美机构'
  }, {
    icon: 'plate4_icon8',
    title: '了解更多'
  }]
}