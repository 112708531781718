import axios from 'axios';

export async function submit(name, intention, phone, wechat) {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('intention', intention);
  formData.append('phone', phone);
  formData.append('wechat', wechat);
  const result = axios.post('/api/Official/saveOfficialApply', formData).catch(result => result);
  return result
}

export function saveOfficialFrom(data) {
	return axios.post('/api/Official/saveOfficialFrom', data)
}

// 官网访问点击
export function visitClick(data) {
	return axios.post('/api/Visit/visitClick', data)
}