import React from 'react';
import { Carousel } from 'antd';
import styles from '../../css/campus_distribution.module.scss';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

// components
import Title from '../../components/Title';

const plate2 = {
  processList: ['用户/商家下单', '骑手A接单配送', '送达至中转站', '骑手B接单配送', '送达至配送地址'],
  iconArr: [
    {
      text: '中转配送',
      icon: 'plate2Icon1'
    },
    {
      text: '预设地址',
      icon: 'plate2Icon2'
    },
    {
      text: '智能调度',
      icon: 'plate2Icon3'
    },
    {
      text: '用户自选配送池',
      icon: 'plate2Icon4'
    }
  ],
  iconArr2: [
    {
      text: '扫码录/接单',
      icon: 'plate2Icon5'
    },
    {
      text: '拍照回传',
      icon: 'plate2Icon6'
    }
  ]
}
const plate3List = [
  {
    title: '成都某校园专送平台',
    cont: '趣来达系统，可对接美团/饿了么平台的订单，为校外自配送商家送餐到校内，送到寝室。',
    image: 'plate3List1'
  },
  {
    title: '温州某校园跑腿配送平台',
    cont: '学生创业者,购买趣来达跑腿系统搭建了校园跑腿服务平台,为学生提供丰富的跑腿配送服务。',
    image: 'plate3List2'
  },
  {
    title: '重庆某大学城',
    cont: '某学生创业者，现已开通多个学校的分站点运营，打造了重庆大学城的跑腿配送联盟平台。',
    image: 'plate3List3'
  }
]

const plate4List = [
  {
    text: '页面模板',
    icon: 'plate4List1'
  },
  {
    text: '场景自定义',
    icon: 'plate4List2'
  },
  {
    text: '自由计价',
    icon: 'plate4List3'
  },
  {
    text: '平台公告',
    icon: 'plate4List4'
  },
  {
    text: '跳转外部小程序',
    icon: 'plate4List5'
  },
  {
    text: '收取件码',
    icon: 'plate4List6'
  },
  {
    text: '中转配送',
    icon: 'plate4List7'
  },
  {
    text: '扫码录单',
    icon: 'plate4List8'
  },
  {
    text: '拍照回传',
    icon: 'plate4List9'
  },
  {
    text: '智能调度',
    icon: 'plate4List10'
  },
  {
    text: '回程单',
    icon: 'plate4List11'
  },
  {
    text: '多订单规划',
    icon: 'plate4List12'
  },
  {
    text: '就近购买',
    icon: 'plate4List13'
  },
  {
    text: '店铺代买',
    icon: 'plate4List14'
  },
  {
    text: '好友代付',
    icon: 'plate4List15'
  },
  {
    text: '事后结算',
    icon: 'plate4List16'
  },
  {
    text: '附加费用',
    icon: 'plate4List17'
  },
  {
    text: '预设地址',
    icon: 'plate4List18'
  }
]

let pageTimer = '' //停留时间
class CampusDistribution extends React.Component {
  state = {
    phone: '',
    baneerIndex: 0,
    plate2Index: 0,
    plate2Index2: 1, // 方案二index
  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'distribution'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.com',
        page: 'distribution',
        time: tempTimer
      },0)
    }
  }

  processToIndex = index => {
    this.setState({
      plate2Index: Number(index)
    })
  }
  processToIndex2 = index => {
    this.setState({
      plate2Index2: Number(index)
    })
  }
  render() {
    return (
      <div className="w-100" >
        <Helmet>
          <title>趣来达-校园跑腿配送|校园跑腿小程序|校园代取快递小程序</title>
          <meta name="Description" content="趣来达校园跑腿配送针对学校配送男女骑手不能混进寝室，以及配送不集中导致效率低的配送的难题，趣来达校园配送调度系统推出智能调度、中转配送、预设地址、用户自选配送池等多种方案；针对订单量大的商家无法通过手动或者拍照发单方式的，支持商家通过二维码下单，骑手扫码录单，到指定地点拍照定位，实时回传位置信息。" />
          <meta name="Keywords" content="校园跑腿配送,校园跑腿平台,校园调度系统,校园代取快递小程序"/>
        </Helmet>
        <Carousel autoplaySpeed={5000} dots={true} autoplay={true} afterChange={(a) => { this.setState({ baneerIndex: Number(a) }) }}>
          <div className={`d-flex align-items-center ${styles.plate1Banner}`} >
            <div style={{ marginLeft: '240px' }} className={classnames('animated', { fadeInRight: this.state.baneerIndex === 0, 'd-none': this.state.baneerIndex !== 0 })}>
              <div className="mb-10" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>深耕<span style={{ color: '#F6BB07' }}>校园配送</span>服务市场，优化校园服务场景</div>
              <span className="banner-text-span" style={{ fontSize: '36px', color: '#FFFFFF', fontWeight: "bold" }}>解决校园跑腿配送平台运营难题</span>
              <div className='Carousel-button d-flex animated fadeInRight'>
                <input type="number" value={this.state.phone} onChange={(e) => { if (e.target.value.length > 11) e.target.value = e.target.value.slice(0, 11); this.setState({phone:e.target.value})}} placeholder='请输入您的手机号' />
                <div onClick={()=> {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.com',
                    page: 'distribution',
                    button: 'onTrial'
                  },0);
                  this.props.submit(this.state.phone)
                }} className='Carousel-btn'>立即试用</div>
              </div>
            </div>
          </div>
        </Carousel>
        {/**配送调度解决方案  板块2**/}
        <div style={{ marginTop: '80px', background: '#ffffff' }}>
          <Title title="配送调度解决方案"
            text="DISTRIBUTION SCHEDULING SOLUTION"
            width={710}
            height={66} />
          <div className={styles.plate2}>
            <div className={styles.allBtn}>方案一</div>
            <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
              <div className={styles.plate2Item}>
                <span className={styles.plate2Cont}>针对学校配送男女骑手不能混进寝室，以及配送不集中导致效率低的配送的难题，趣来达校园配送调度系统推出智能调度、中转配送、预设地址、用户自选配送池等多种方案。</span>
                {/* 流程 */}
                <div className={styles.process} style={{ marginBottom: '80px' }}>
                  {plate2.processList.map((row, index) => {
                    return (
                      <div className={styles.process} key={index} onMouseOver={() => { this.processToIndex(index) }}>
                        <div className={this.state.plate2Index === index ? `${styles.processItem} ${styles.processItemActive}` : `${styles.processItem}`}>{row}</div>
                        {row !== '送达至配送地址' && <img alt='' src={require(`../../asset/campus_distribution/process.png`)} style={{ width: '25px', height: '24px' }} />}
                      </div>
                    )
                  })}
                </div>
                <div className='d-flex' style={{ marginLeft: '-25px' }}>
                  {plate2.iconArr.map((item, indexs) => {
                    return (
                      <div className={styles.iconItem} key={indexs}>
                        <img alt='' src={require(`../../asset/campus_distribution/${item.icon}.png`)} style={{ width: '60px', height: '60px' }} />
                        <span>{item.text}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <img alt='' src={require(`../../asset/campus_distribution/plate2Image.png`)} style={{ width: '410px', height: '410px' }} />
            </div>
          </div>
          <div className={styles.plate2Two}>
            <div className={styles.allBtn} style={{ margin: '0 auto' }}>方案二</div>
            <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
              <img alt='' src={require(`../../asset/campus_distribution/plate2Image1.png`)} style={{ width: '410px', height: '410px' }} />
              <div className={styles.plate2ItemTwo}>
                <span className={styles.plate2Cont}>针对订单量大的商家无法通过手动或者拍照发单方式的，支持商家通过二维码下单，骑手扫码录单，到指定地点拍照定位，实时回传位置信息。</span>
                {/* 流程 */}
                <div className={styles.processBox}>
                  <div className={styles.process} onMouseOver={() => { this.processToIndex2(1) }}>
                    <div className={this.state.plate2Index2 === 1 ? `${styles.processItem} ${styles.processItemActive} ${styles.twoBg}` : `${styles.processItem}`}>商家贴单</div>
                    <img alt='' src={require(`../../asset/campus_distribution/process.png`)} style={{ width: '25px', height: '24px' }} />
                  </div>
                  <div className={styles.process} onMouseOver={() => { this.processToIndex2(2) }}>
                    <div className={this.state.plate2Index2 === 2 ? `${styles.processItem} ${styles.processItemActive} ${styles.twoBg}` : `${styles.processItem}`}>用户下单</div>
                    <img alt='' src={require(`../../asset/campus_distribution/process.png`)} style={{ width: '25px', height: '24px' }} />
                  </div>
                </div>
                <div className={`${styles.process} ${styles.processPosition}`}>
                  <div className={styles.process} onMouseOver={() => { this.processToIndex2(3) }}>
                    <div className={this.state.plate2Index2 === 3 ? `${styles.processItem} ${styles.processItemActive} ${styles.twoBg}` : `${styles.processItem}`}>骑手<br />扫码接单</div>
                    <img alt='' src={require(`../../asset/campus_distribution/process.png`)} style={{ width: '25px', height: '24px' }} />
                  </div>
                  <div className={styles.process} onMouseOver={() => { this.processToIndex2(4) }}>
                    <div className={this.state.plate2Index2 === 4 ? `${styles.processItem} ${styles.processItemActive} ${styles.twoBg}` : `${styles.processItem}`}>配送服务</div>
                  </div>
                </div>
                <div className='d-flex' style={{ marginLeft: '-25px' }}>
                  {plate2.iconArr2.map((item, indexs) => {
                    return (
                      <div className={styles.iconItem} key={indexs}>
                        <img alt='' src={require(`../../asset/campus_distribution/${item.icon}.png`)} style={{ width: '60px', height: '60px' }} />
                        <span>{item.text}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**案例分析  板块3**/}
        <div style={{ background: '#F7F9FC', padding: '100px 0' }}>
          <Title title="案例分析"
            text="CASE ANALYSIS"
            width={710}
            height={66} />
          <div className={styles.plate3}>
            {plate3List.map((item, index) => {
              return (
                <div className={styles.plate3Item} key={index}>
                  <img alt='' src={require(`../../asset/campus_distribution/${item.image}.png`)} style={{ width: '100%', height: '158px' }} />
                  <div className={styles.plate3ItemTitle}>{item.title}</div>
                  <div className={styles.plate3ItemCont}>{item.cont}</div>
                </div>
              )
            })}
          </div>
        </div>
        {/**功能内容  板块4**/}
        <div style={{ background: '#FFFFFF', padding: '100px 0 0' }}>
          <Title title="功能内容"
            text="FUNCTION CONTENT"
            width={710}
            height={66} />
          <div className={styles.plate4Box}>
            <div className={styles.plate4}>
              {plate4List.map((item, index) => {
                return (
                  <div className={styles.plate4IconItem} key={index}>
                    <img alt='' src={require(`../../asset/campus_distribution/${item.icon}.png`)} style={{ width: '100px', height: '100px' }} />
                    <span>{item.text}</span>
                  </div>
                )
              })}
            </div>
            <div className={styles.plate4Btn} onClick={() => {
              this.props.onVisitClick({
                type: 3,
                domain: 'www.qolai.com',
                page: 'distribution',
                button: 'moreFun'
              },0);
              this.props.showDialog()
            }} style={{ cursor: 'pointer' }}>查看更多功能 <span style={{ fontWeight: '700' }}>More+</span></div>
          </div>
        </div>
      </div>
    );
  }
}

export default CampusDistribution;