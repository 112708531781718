import React, {useEffect} from "react";
import { Row, Col } from "antd";
import { Helmet } from 'react-helmet';
// components
import Title from "../../components/Title";
import CarouselIndex from "../../components/Carousel";
// img
import title1 from "../../asset/caselist/title1.png";
import title2 from "../../asset/caselist/title2.png";
import title3 from "../../asset/caselist/title3.png";

//backgroundImg
import backgroundImg1 from '../../asset/backgroud_img/img_process1.png';
import backgroundImg2 from '../../asset/backgroud_img/img_process2.png';
import backgroundImg3 from '../../asset/backgroud_img/img_process3.png';
import backgroundImg4 from '../../asset/backgroud_img/img_process4.png';
import backgroundImg5 from '../../asset/backgroud_img/img_process5.png';
import backgroundImg6 from '../../asset/backgroud_img/img_process6.png';
const Arr = [
  {
    image: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/plate3-icon1.png",
    city: "重庆·云阳",
    text: "同城生活平台",
    mask: backgroundImg1
  },
  {
    image: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/plate3-icon2.png",
    city: "湖北·巴东",
    text: "跑腿配送团队",
    mask: backgroundImg2
  },
  {
    image: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/plate3-icon3.png",
    city: "天津·静海",
    text: "跑腿公司",
    mask: backgroundImg3
  },
  {
    image: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/plate3-icon4.png",
    city: "山东·济南",
    text: "校园O2O平台",
    mask: backgroundImg6
  },
  {
    image: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/plate3-icon5.png",
    city: "青海·西宁",
    text: "同城生活平台",
    mask: backgroundImg5
  },
  {
    image: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/plate3-icon6.png",
    city: "广东·河源",
    text: "同城配送团队",
    mask: backgroundImg4
  }
];

const Carousel_text = [
  {
    carouselImage: "caselist/img_banner",
    text: [
      { text: "深耕", colorText: "" },
      { text: "", colorText: "本地生活服务市场", br: true },
      { text: "历经上千合作平台实战打磨", colorText: "" }
    ],
    desc: " ",
    scenes: []
  }
];

let pageTimer = '' //停留时间


function CaseList(props) {
  useEffect(() => {
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    props.onVisitClick({
      type: 1,
      domain: 'www.qolai.com',
      page: 'case'
    }, 0)

    // 这个返回的函数，会在该组件卸载时来执行
    // 因此，可以去执行一些清理操作，比如，解绑 window 的事件、清理定时器 等
    return () => {
      // 离开时间减去进入时间
      const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
      console.log(tempTimer, 'tempTimer')
      // 清空时间戳
      pageTimer = '';
      if (tempTimer > 0) {
        props.onVisitClick({
          type: 2,
          domain: 'www.qolai.com',
          page: 'case',
          time: tempTimer
        }, 0)
      }
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>趣来达-外卖配送调度系统|电商配送系统|配送骑手APP</title>
        <meta name="Description" content="趣来达是一套同城/调度/配送、代办、代取类软件；有派单+放群抢单+指派订单，专注解决同城配送，最后一公里配送调度问题；开放各电商、外卖、和其他的第三方的API接口，实现各平台数据对接和流通；打造功能强大、丰富的跑腿配送系统与跑腿平台创业者共谋发展。" />
        <meta name="Keywords" content="外卖送餐软件,电商配送系统,外卖配送调度系统,配送骑手APP" />
      </Helmet>
      {/**顶部banner**/}
      <CarouselIndex data={Carousel_text} />
      {/**趣来达跑腿系统已服务覆盖**/}
      <div className="caselist-main">
        <Title title="趣来达外卖配送系统已服务覆盖" imgUrl={title1} text="service coverage" width={861} height={68} />
        <img
          alt=""
          src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/map.gif"
          style={{ width: 810, height: 540, margin: "auto", display: "block", marginBottom: 25 }}
        />
        <Row type="flex" justify="center" style={{ padding: "0 0 100px 0" }}>
          <Col className="caselist-plate2-item d-flex align-items-center">
            <div className="w-100">
              <h4 className="m-0">20+</h4>
              <div className="item-box d-flex align-items-center justify-content-between">
                <span>省覆盖</span>
                <div>
                  <img alt="" src={require("../../asset/caselist/plate1-icon2.png")} />
                  <img alt="" className="plate1-right-bottom-icon" src={require("../../asset/caselist/plate1-icon4.png")} />
                </div>
              </div>
            </div>
          </Col>
          <Col className="caselist-plate2-item d-flex align-items-center">
            <div className="w-100">
              <h4 className="m-0">480+</h4>
              <div className="item-box d-flex align-items-center justify-content-between">
                <span>市县区域</span>
                <div>
                  <img alt="" src={require("../../asset/caselist/plate1-icon3.png")} />
                  <img alt="" className="plate1-right-bottom-icon" src={require("../../asset/caselist/plate1-icon4.png")} />
                </div>
              </div>
            </div>
          </Col>
          <Col className="caselist-plate2-item d-flex align-items-center">
            <div className="w-100">
              <h4 className="m-0">4000+</h4>
              <div className="item-box d-flex align-items-center justify-content-between">
                <span>跑腿平台</span>
                <div>
                  <img alt="" src={require("../../asset/caselist/plate1-icon1.png")} />
                  <img alt="" className="plate1-right-bottom-icon" src={require("../../asset/caselist/plate1-icon4.png")} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/**您对趣来达的选择与长久合作 是我们不懈的追求**/}
      <div className="caselist-plate2">
        <Title
          title="您对趣来达的选择与长久合作，是我们不懈的追求"
          text="longstanding cooperation"
          imgUrl={title2}
          width={926}
          height={68}
        />
        <Row type="flex" justify="center">
          <Col className="plate2-item-box">
            <div className="plate2-item-main">
              <img alt="" src={require("../../asset/caselist/plate2-img1.png")} className="plate2-item-img" />
              <span style={{ color: "#333333" }} className="d-flex align-items-center justify-content-center mx-auto">
                跑腿配送公司
              </span>
            </div>
            <div className="plate2-item-dec text-center" style={{ fontWeight: 600 }}>
              跑腿配送系统千篇一律
              <br />
              能真正解决痛点的所剩无几
              <br />
              趣来达跑腿配送平台系统
              <br />
              集运营、管理、调度高效一体
              <br />
              以有竞争力跑腿系统
              <br />
              抢占千亿级跑腿配送市场
            </div>
          </Col>
          <Col className="plate2-item-box">
            <div className="plate2-item-main">
              <img alt="" src={require("../../asset/caselist/plate2-img2.png")} className="plate2-item-img" />
              <span style={{ color: "#333333" }} className="d-flex align-items-center justify-content-center mx-auto">
                同城配送创业团队
              </span>
            </div>
            <div className="plate2-item-dec text-center" style={{ fontWeight: 600 }}>
              跑腿平台生存法则千千万
              <br />
              凭什么不选择容易成功的那个
              <br />
              趣来达跑腿配送平台系统
              <br />
              功能强大、响应及时、运营有序
              <br />
              助力万千同城配送创业团队
              <br />
              打造专属品牌本地跑腿配送平台
            </div>
          </Col>
          <Col className="plate2-item-box">
            <div className="plate2-item-main">
              <img alt="" src={require("../../asset/caselist/plate2-img3.png")} className="plate2-item-img" />
              <span style={{ color: "#333333" }} className="d-flex align-items-center justify-content-center mx-auto">
                快递公司
              </span>
            </div>
            <div className="plate2-item-dec text-center" style={{ fontWeight: 600 }}>
              如何把配送服务的价值发挥大？
              <br />
              打造自己的配送服务圈
              <br />
              为新老客户提供更多配送服务
              <br />
              多元的配送服务场景
              <br />
              提升单量，增加配送员额外收入
              <br />
              提升团队整体运营效率与营收额
            </div>
          </Col>
        </Row>
      </div>
      {/**趣来达跑腿配送系统千万合作商的共同选择**/}
      <div className="caselist-plate3">
        <Title title="趣来达合作商家展示" text="BUSINESS COOPERATION" imgUrl={title3} width={880} height={68} />
        <Row type="flex" justify="center">
          <div className="caselist-plate4-item">
            {Arr.map((item, index) => (
              <div className="caselist-plate4-content d-flex" key={index} style={{ flexDirection: "column" }}>
                <div className="content-top" style={{ backgroundImage: `url(${item.mask})` }}>
                  <img src={item.image} alt="icon" />
                  <div className='blate5-list-maks' style={{ borderRadius: ' 12px 12px 12px 12px' }}></div>
                </div>
                <div className="content-bottom">
                  <span>{item.text}</span>
                  <div className="city">{item.city}</div>
                  <div className="content-code d-flex">
                    <img
                      alt=""
                      style={{ width: 100, height: 100 }}
                      src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/home/img_code_fz.png"
                    />
                    <span style={{ fontWeight: 400, fontSize: 12, color: "#333333" }}>- 扫码体验 -</span>
                    <span style={{ fontWeight: 700, fontSize: 16, color: "#333333", marginTop: 5 }}>17721872191(微信同号)</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Row>
      </div>
    </div>
  );
}

export default CaseList;
