import axios from 'axios';
import {message} from 'antd';

axios.defaults.baseURL = "https://news.yiliit.com/";

axios.interceptors.request.use((config) => {
    return config
}, error => Promise.reject(error));

axios.interceptors.response.use((response) => {
    return response.data ? response.data : true;
}, (error) => {
    message.error('数据获取失败，请重试！');
    return false;
})